<template>
    <!-- Activation page-->
    <div class="misc-wrapper">
        <b-link class="brand-logo">
            <vuexy-logo />
            <h2 class="brand-text text-primary ml-1">
                Rentila
            </h2>
        </b-link>
    
        <div v-if="verified" class="misc-inner p-2 p-sm-3">

            <div class="text-center">
                <h1 class="pl-2 text-center">
                    {{ i18nT(`Crée un nouveau mot de passe`) }}!
                </h1>

                <b-img
                    fluid
                    :src="imgUrl"
                    alt="Side img"
                    class="my-2"
                    :width="400"
                />
            </div>

            <validation-observer
                ref="resetPasswordForm"
            >
                <b-form
                    @submit.prevent="resetPassword"
                >
                    <b-form-group
                        label-for="reset-password"
                        :label="i18nT(`Create a new password`)"
                    >
                        <validation-provider
                            #default="{ errors }"
                            name="Create a new password"
                            vid="password"
                            rules="required|passwordRule"
                        >
                            <b-input-group
                                class="input-group-merge required"
                                :class="errors.length > 0 ? 'is-invalid':null"
                            >
                                <b-form-input
                                    id="reset-password"
                                    v-model="password"
                                    class="form-control-merge"
                                    :type="passwordFieldType"
                                    :state="errors.length > 0 ? false:null"
                                    name="reset-password"
                                    :placeholder="i18nT(`············`)"
                                />
                                <b-input-group-append is-text>
                                    <feather-icon
                                        :icon="passwordToggleIcon"
                                        class="cursor-pointer"
                                        @click="togglePasswordVisibility"
                                    />
                                </b-input-group-append>
                            </b-input-group>
                            <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                    </b-form-group>

                        <!--TODO - password rules needs to be localized-->
                    <div class="password-rules font-small-3">
                        <div class="pb-1">{{i18nT(`To ensure that your account and information is well protected, please chose a password that has: `)}}</div>
                        <div class="d-flex">
                            <div class="rule-column pr-2">
                                <div :class="{ 'rule-met': isLengthValid }"><font-awesome-icon :icon="isLengthValid ? `check` : `times`" class="mr-1" :class="isLengthValid ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`Minimum 8 characters`)}}</div>
                                <div :class="{ 'rule-met': hasUppercase }"><font-awesome-icon :icon="hasUppercase ? `check` : `times`" class="mr-1" :class="hasUppercase ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one uppercase letter`)}}</div>
                            </div>
                            <div class="rule-column">
                                <div :class="{ 'rule-met': hasNumber }"><font-awesome-icon :icon="hasNumber ? `check` : `times`" class="mr-1" :class="hasNumber ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one number`)}}</div>
                                <div :class="{ 'rule-met': hasSpecialCharacter }"><font-awesome-icon :icon="hasSpecialCharacter ? `check` : `times`" class="mr-1" :class="hasSpecialCharacter ? `text-success`: `text-danger`"></font-awesome-icon>{{i18nT(`At least one special character`)}}</div>
                            </div>
                        </div>
                        <!-- Add other rules as needed -->
                    </div>

                    <b-button
                        variant="primary"
                        block
                        type="submit"
                        class="mt-3"
                    >
                        {{i18nT(`Allez vous connecter`)}}
                    </b-button>
                </b-form>
            </validation-observer>    
        </div>

        <div v-if="!verified" class="misc-inner p-2 p-sm-3">
            <div class="w-100 text-center">
            <h1 class="mb-2 pl-2">
                {{ i18nT(`Il y a eu un problème avec le lien de réinitialisation`) }}
            </h1>
            <p class="mb-2">
                {{ i18nT(`Cliquez sur le bouton ci-dessous pour accéder à l'écran de connexion`) }}.
            </p>

            <!-- image -->
            <b-img
                fluid
                :src="downImg"
                alt="Error page"
                class="w-70"
            />

            <b-button
                variant="primary"
                class="mb-2 btn-sm-block"
                :to="{path:'/login'}"
                :width="100"
            >
                {{ i18nT(`Allez vous connecter`)}}
            </b-button>
    
            </div>
        </div>
    </div>
  <!-- / Activation page-->
</template>
  
<script>
import { 
    BLink, 
    BButton, 
    BImg, 
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
} from 'bootstrap-vue'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { passwordRule } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { useUtils as useI18nUtils } from '@core/libs/i18n';
const { i18nK } = useI18nUtils();
function i18nT(str){
    return i18nK(str)
}

extend('passwordRule', {
    validate(value) {
        return value.length >= 8 && /[A-Z]/.test(value) && /\d/.test(value) && /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value); // Example criteria
    },
    message: i18nT(`Password does not meet requirements`),
});

export default {
    components: {
        VuexyLogo,
        BLink,
        BButton,
        BImg,
        BForm,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        ValidationProvider,
        ValidationObserver,
    },
    mixins: [togglePasswordVisibility],
    data() {
        return {
            verified: null,
            downImg: require('@/assets/images/pages/error.svg'),
            sideImg: require('@/assets/images/pages/login-v2.svg'),
            hash: '',
            password: '',
        }
    },
    computed: {
        DownImgUrl() {
        if (store.state.appConfig.layout.skin === 'dark') {
            this.downImg = require('@/assets/images/pages/error-dark.svg')
            return this.downImg
        }
        return this.downImg
        },
        imgUrl() {
            if (store.state.appConfig.layout.skin === 'dark') {
                this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
                return this.sideImg
            }
            return this.sideImg
        },
        isLengthValid() {
            return this.password.length >= 8;
        },
        hasUppercase() {
            return /[A-Z]/.test(this.password);
        },
        hasSpecialCharacter() {
            return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(this.password);
        },
        hasNumber() {
            return /\d/.test(this.password);
        },
        passwordToggleIcon() {
            return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
        },
    },
    created() {

        this.hash = this.$route.query.hash

        this.$http.post('/forgotten/verifyToken', {
            'hash': this.hash
        })
        .then(() => {
            this.verified = true
        })
        .catch((err) => {
            this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                    title: this.errorMessage(err),
                    icon: 'CoffeeIcon',
                    variant: 'danger',
                    text: this.i18nT(`Please try again later.`)
                }
            })
            this.verified = false
        })
    },
    methods: {
        resetPassword() {
            this.$refs.resetPasswordForm.validate().then(success => {
                if(success) {
                
                    const formData = new FormData()

                    formData.append('hash', this.hash)
                    formData.append('password', this.password)


                    this.$http.post('forgotten/processToken', formData)
                    .then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.i18nT(`Password was successfully reset`),
                                icon: 'CoffeeIcon',
                                variant: 'success',
                            }
                        })

                        this.$router.push({name: 'auth-login'});
                        
                    })
                    .catch((err) => {
                        this.$toast({
                            component: ToastificationContent,
                            position: 'top-right',
                            props: {
                                title: this.errorMessage(err),
                                icon: 'CoffeeIcon',
                                variant: 'danger',
                                text: this.i18nT(`Please try again later.`)
                            }
                        })
                    })
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
  